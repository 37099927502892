import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import Swiper from "swiper";
import { Pagination, EffectFade, Autoplay } from "swiper/modules";

Array.from(document.querySelectorAll(".js-slider-banner")).forEach(
  (container) => {
    const slider = container.querySelector(".swiper");
    const pagination = container.querySelector(".swiper-pagination");

    const swiper = new Swiper(slider, {
      modules: [Pagination, EffectFade, Autoplay],
      loop: true,
      autoHeight: true,
      speed: 1000,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: pagination,
        clickable: true,
      },
      autoplay: {
        delay: 8000,
      },
    });
  }
);
